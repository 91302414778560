import { combineReducers } from "redux";
import adminReducer from "./AdminReducer";
import subAdminReducer from "./subAdminReducer";
import userReducer from "./userReducer";
import productReducer from "./productReducer";
import serviceReducer from "./serviceReducer";
import subServiceReducer from "./subServiceReducer";
import appointmentReducer from "./appointmentReducer";
import lastMinuteSlotReducer from "./lastMinuteSlotReducer";
import logInReducer from "./loginReducer";
import offerReducer from "./offerReducer";
import voucherReducer from "./voucherReducer";
import loyaltySchemeReducer from "./loyaltySchemeReducer";
import blogReducer from './BlogReducer'
import broadcastNotificationReducer from './broadcastNotificationReducer'
import privacyPolicyReducer from './PrivacyPolicyReducer'
import termsAndConditionReducer from './TermsAndConditionReducer'

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  logInToken: logInReducer,
  sidebarShow: changeState,
  adminData: adminReducer,
  subAdminData: subAdminReducer,
  productData: productReducer,
  userData: userReducer,
  serviceData: serviceReducer,
  subServiceData: subServiceReducer,
  appointmentData: appointmentReducer,
  lastMinuteSlotData: lastMinuteSlotReducer,
  offerData: offerReducer,
  voucherData: voucherReducer,
  loyaltySchemeData: loyaltySchemeReducer,
  blogData: blogReducer,
  broadcastNotificationData: broadcastNotificationReducer,
  privacyPolicyData: privacyPolicyReducer,
  termsAndConditionData: termsAndConditionReducer
});

export default rootReducer;
