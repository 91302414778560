const initialState = {
  data: [],
};

const getSubAdminData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SUBADMIN_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default getSubAdminData;
